import "ant-design-vue/es/message/style/css";
import _message from "ant-design-vue/es/message";
import { defineComponent, ref, onMounted, reactive, toRefs, computed, nextTick, onUnmounted } from 'vue';
import { selectDeviceSettings, updateHyDeviceSettings, selectNotUserDevices } from '/src/api/global';
export default {
  setup() {
    //通道设置
    const btnLoading = ref(false);
    const channelVisible = ref(false);
    const formRefChannel = ref();
    const deviceNoList = ref([]);
    const formStateTypes = reactive({
      channelTypes: '0',
      deviceNo: [],
      deviceNo1: [],
      deviceNo2: []
    });

    const toArrString = arr => {
      let newArr = [];
      arr.filter(item => {
        newArr.push(item);
      });

      if (newArr != []) {
        newArr = newArr.toString();
      } else {
        newArr = '';
      }

      return newArr;
    };

    const onSubmitTypes = () => {
      formRefChannel.value.validate().then(() => {
        let arr = [];

        if (formStateTypes.channelTypes == 0) {
          arr = [{
            deviceNo: toArrString(formStateTypes.deviceNo),
            deviceType: null
          }];
        } else {
          let er = 0;
          formStateTypes.deviceNo1.filter(item1 => {
            formStateTypes.deviceNo2.filter(item2 => {
              if (item1 == item2) {
                er = 1;
              }
            });
          });

          if (er == 1) {
            _message.error('进出面板机所选SN号有重合，请修改！');

            return;
          }

          arr = [{
            deviceNo: toArrString(formStateTypes.deviceNo1),
            deviceType: 0
          }, {
            deviceNo: toArrString(formStateTypes.deviceNo2),
            deviceType: 1
          }];
        }

        let setData = {
          channelType: Number(formStateTypes.channelTypes),
          ipAddrs: arr
        };
        changeChannel(setData);
      }).catch(error => {
        console.log('error', error);
      });
    };

    const handleCancelChannel = () => {
      channelVisible.value = false;
      formRefChannel.value.resetFields();
      deviceNoList.value = [];
    };

    const timeVisible = ref(false);

    const onSubmitTime = () => {
      formRefTime.value.validate().then(() => {
        let updataTime = {
          id: formStateTime.id,
          workStartAmTime: formStateTime.workStartAmTime.slice(0, 5),
          workEndAmTime: formStateTime.workEndAmTime.slice(0, 5),
          workStartPmTime: formStateTime.workStartPmTime.slice(0, 5),
          workEndPmTime: formStateTime.workEndPmTime.slice(0, 5),
          overTime: formStateTime.overTime.slice(0, 5),
          overEndTime: formStateTime.overEndTime == '' || formStateTime.overEndTime == null ? null : formStateTime.overEndTime.slice(0, 5)
        };
        updateHySettingsByParams1(updataTime);
      }).catch(error => {
        console.log('error', error);
      });
    };

    const formStateTime = reactive({
      id: '',
      workStartAmTime: '',
      workEndAmTime: '',
      workStartPmTime: '',
      workEndPmTime: '',
      overTime: '',
      overEndTime: ''
    });

    const handleCancelTime = () => {
      timeVisible.value = false; //formRefChannel.value.resetFields();
    };

    let channelType = null; //获取通道设置

    const channelSet = async () => {
      channelVisible.value = true;
      let res = await selectDeviceSettings();

      if (res.code === 200) {
        console.log(res.data);
        let data = res.data.ipAddrs;
        channelType = res.data.channelType;

        if (data.length == 1) {
          Object.assign(formStateTypes, {
            channelTypes: res.data.channelType,
            deviceNo: data[0].deviceNo == "" ? [] : data[0].deviceNo.split(","),
            deviceNo1: [],
            deviceNo2: []
          });
        } else if (data.length == 2) {
          Object.assign(formStateTypes, {
            channelTypes: res.data.channelType,
            deviceNo: [],
            deviceNo1: data[0].deviceType == 0 ? data[0].deviceNo == "" ? [] : data[0].deviceNo.split(",") : data[1].deviceNo == "" ? [] : data[1].deviceNo.split(","),
            deviceNo2: data[0].deviceType == 0 ? data[1].deviceNo == "" ? [] : data[1].deviceNo.split(",") : data[0].deviceNo == "" ? [] : data[0].deviceNo.split(",")
          });
        }
      } else {
        _message.error(res.message);
      }
    }; //修改通道设置


    const changeChannel = async val => {
      btnLoading.value = true;
      console.log(val);
      let res = await updateHyDeviceSettings(val);

      if (res.code === 200) {
        btnLoading.value = false;

        _message.success(res.message); //通道从双通道改为单通道提示


        if (channelType == 1 && formStateTypes.channelTypes == 0) {
          _message.warning('现场施工总人数已归零！');
        }

        handleCancelChannel();
      } else {
        btnLoading.value = false;

        _message.error(res.message);
      }
    }; //获取面板机列表


    const selectNotUserDevices1 = async () => {
      let res = await selectNotUserDevices();

      if (res.code === 200) {
        if (res.data != []) {
          res.data.filter((item, index) => {
            deviceNoList.value.push({
              value: item.deviceNo,
              label: item.deviceNo
            });
          });
        }
      } else {
        _message.error(res.message);
      }
    };

    onMounted(() => {
      channelVisible.value = true;
      channelSet();
      selectNotUserDevices1();
    });
    return {
      channelVisible,
      formRefChannel,
      deviceNoList,
      formStateTypes,
      toArrString,
      onSubmitTypes,
      handleCancelChannel,
      timeVisible,
      onSubmitTime,
      handleCancelTime,
      channelSet,
      selectNotUserDevices1,
      labelCol: {
        span: 6
      },
      wrapperCol: {
        span: 14
      },
      btnLoading
    };
  }

};